import { useNavigation } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import LoadingBar, { type LoadingBarRef } from 'react-top-loading-bar';
import { usePrevious } from 'react-use';

export function PageLoadingBar(props: { color?: string; height?: number }) {
  const navigation = useNavigation();
  const ref = useRef<LoadingBarRef>(null);
  const prevState = usePrevious(navigation.state);

  useEffect(() => {
    if (prevState === 'idle' && navigation.state === 'loading') {
      ref.current?.continuousStart();
    } else if (prevState === 'loading' && navigation.state === 'idle') {
      ref.current?.complete();
    }
  }, [navigation.state, prevState]);

  return <LoadingBar color={props.color} height={props.height} ref={ref} />;
}
